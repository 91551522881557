<template>
  <div class="d-flex flex-column justify-center" style="width: 100%">
    <v-container class="d-flex flex-column" style="max-width: 1480px">
      <component
        v-if="contentTemplate !== ''"
        :is="{
          name: 'dynamic-content',
          template: this.contentTemplate,
        }"
      />
    </v-container>
    <foo-ter />
  </div>
</template>

<script>
import template from "../data/aboutus.json";
export default {
  data() {
    return {
      contentTemplate: template.template.content,
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          property: "description",
          content: "Loviisan Aitta Meistä",
        },
      ],
    };
  },
  created: function() {
    if (template.template.data) {
      this.$store.commit("updateCMSData", template.template.data);
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .life-style-content,
  .family {
    flex-direction: column;
    align-items: center;
  }

  .collection {
    flex-direction: column-reverse;
    align-items: center;
  }

  .life-style-content .v-image {
    margin: 0 auto 32px 0 !important;
  }

  .container .v-image {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
